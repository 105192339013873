import React, { useState } from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Switch,
  Slider,
  Button,
  Rate,
  Typography,
  Space,
  Divider,
  Radio,
  Alert
} from 'antd';
import './App.less';
import { DownloadOutlined } from '@ant-design/icons';
import { Calendar } from 'antd';

const { Option } = Select;
const { Title } = Typography;

const App = () => {
  const [size, setSize] = useState('large');

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  return (
  <>
    <section style={{ textAlign: 'center', marginTop: 48, marginBottom: 40 }}>
      <Space align="start">
        <img
          style={{width: 40, height: 40 }}
          src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
          alt="Ant Design"
        />
        <Title level={2} style={{ marginBottom: 0 }}>
          Ant Design
        </Title>
      </Space>
    </section>
      
    <Divider style={{ marginBottom: 60 }}>Form</Divider>
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
      <Form.Item label="Number">
        <InputNumber min={1} max={10} defaultValue={3} variant='primary'/>
        <span className="ant-form-text"> Ant form text</span>
        <a href="https://ant.design">Link</a>
      </Form.Item>
      <Form.Item label="Switch">
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item label="Slider input bar">
        <Slider defaultValue={70} />
      </Form.Item>
      <Form.Item label="Selector">
        <Select defaultValue="lucy" style={{ width: 192 }}>
          <Option value="jack">jack</Option>
          <Option value="lucy">lucy</Option>
          <Option value="disabled" disabled>disabled</Option>
          <Option value="yiminghe">yiminghe</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Date selection box">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Date range selection box">
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item label="Score">
        <Rate defaultValue={5} />
      </Form.Item>
      <Divider style={{ marginBottom: 60, marginTop: 60 }}>Typography</Divider>
      <div style={ { display: 'flex', justifyContent: 'space-evenly', gap: '1rem' } }>
        <div>
          <Title level={3}><strong>AntDesign + Normalyze</strong></Title>
          <Title>h1. This is a title</Title>
          <Title level={2}>h2. This is a title</Title>
          <Title level={3}>h3. This is a title</Title>
          <Title level={4}>h4. This is a title</Title>
          <Title level={5}>h5. This is a title</Title>
          <Typography.Paragraph>Paragraph. This is a Paragraph</Typography.Paragraph>
        </div>
        <div style={ { fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji'` } }>
          <Title level={3}><strong>AntDesign Default</strong></Title>
          <Title>h1. This is a title</Title>
          <Title level={2}>h2. This is a title</Title>
          <Title level={3}>h3. This is a title</Title>
          <Title level={4}>h4. This is a title</Title>
          <Title level={5}>h5. This is a title</Title>
          <Typography.Paragraph>Paragraph. This is a paragraph</Typography.Paragraph>
        </div>
      </div>
      <Divider style={{ marginBottom: 60, marginTop: 60 }}>Buttons</Divider>
      <br />
      <Form.Item wrapperCol={{ span: 8, offset: 8 }} >
        <Space>
          <div style={ { display: 'flex', flexDirection: 'column', gap: '5px' } }>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button>
              Cancel
            </Button>
            <Button type='primary' disabled>
              Disabled
            </Button>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginBottom: '1rem' }}>
              <Button type="primary">Primary</Button><Button>Primary Default</Button><Button type='dashed'>Primary Dashed</Button><Button type='link'>Primary Link</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginBottom: '1rem' }}>
              <Button type="primary" danger>Danger</Button><Button danger>Danger Default</Button><Button type='dashed' danger>Danger Dashed</Button><Button type='link' danger>Danger Link</Button>
            </div>
            <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
              <Radio.Button value="large">Large</Radio.Button>
              <Radio.Button value="default">Default</Radio.Button>
              <Radio.Button value="small">Small</Radio.Button>
            </Radio.Group>
            <br />
            <br />
            <Button type="primary" size={size}>
              Primary
            </Button>
            <Button size={size}>Default</Button>
            <Button type="dashed" size={size}>
              Dashed
            </Button>
            <br />
            <Button type="link" size={size}>
              Link
            </Button>
            <br />
            <Button type="primary" icon={<DownloadOutlined />} size={size} />
            <Button type="primary" shape="circle" icon={<DownloadOutlined />} size={size} />
            <Button type="primary" shape="round" icon={<DownloadOutlined />} size={size} />
            <Button type="primary" shape="round" icon={<DownloadOutlined />} size={size}>
              Download
            </Button>
            <Button type="primary" icon={<DownloadOutlined />} size={size}>
              Download
            </Button>

            <Divider style={{ marginBottom: 60, marginTop: 60 }}>Alerts</Divider>

            <Alert message="Success Tips" type="success" showIcon />
            <Alert message="Informational Notes" type="info" showIcon />
            <Alert message="Warning" type="warning" showIcon closable />
            <Alert message="Error" type="error" showIcon />

            <Divider style={{ marginBottom: 60, marginTop: 60 }}>Calendar</Divider>

            <Calendar onPanelChange={onPanelChange} />

            

          </div>
        </Space>
      </Form.Item>
    </Form>
  </>
);
}

export default App;